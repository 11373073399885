export const DEFAULT_CONFIG = {
    allowScriptAccess: false,
    parameters: {},
    autoplay: "auto" /* AutoPlay.Auto */,
    backgroundColor: null,
    letterbox: "fullscreen" /* Letterbox.Fullscreen */,
    unmuteOverlay: "visible" /* UnmuteOverlay.Visible */,
    upgradeToHttps: true,
    compatibilityRules: true,
    warnOnUnsupportedContent: true,
    logLevel: "error" /* LogLevel.Error */,
    showSwfDownload: false,
    contextMenu: true,
    // Backwards-compatibility option
    preloader: true,
    splashScreen: true,
    maxExecutionDuration: 15,
    base: null,
    menu: true,
    salign: "",
    quality: "high",
    scale: "showAll",
    forceScale: false,
    frameRate: null,
    wmode: "opaque" /* WindowMode.Opaque */,
    publicPath: null,
    polyfills: true,
    playerVersion: null,
    preferredRenderer: null,
};
