/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-05-01",
    versionChannel: "nightly",
    buildDate: "2023-05-01T00:19:34.037Z",
    commitHash: "ee7403952afe1436d84f94bcbdd0d23209cb7d39",
};
